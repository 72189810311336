.help-center-root {
    
    min-height: 100vh;
    padding: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .help-center-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .help-title {
    font-size: var(--font_size_bg);
    font-weight: 700;
    color: #1e293b;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .help-subtitle {
    font-size: var(--font_size_header);
    color: #64748b;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .help-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 1.5rem;
  }
  
  .help-card {
    background: var(-body_backgroun);
    border: 1px solid var(--border_color);
    border-radius: 16px;
    padding: 1.5rem;
  
    transition: all 0.3s ease;
  }
  
  .help-card:hover {
    transform: translateY(-6px);
    box-shadow: 0 12px 30px rgba(0, 114, 255, 0.15);
  }
  
  .help-card h2 {
    font-size: var(--font_size_header);
    color: var(--icon-color);
    margin-bottom: 0.5rem;
  }
  
  .help-card p {
    font-size: var(--font_size_normal);
  
    margin-bottom: 1rem;
  }
  
  .help-link {
    color: var(--icon-color);
    font-weight: 500;
    text-decoration: none;
  }
  
  .help-link:hover {
    text-decoration: underline;
  }
  
  .contact-card .contact-icons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.95rem;
  }
  
  .contact-icons a {
    color: #334155;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
 