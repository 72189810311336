/* Font & Base Styling */
 
  
  /* Hero Section */
  .about-hero {
    text-align: center; 
 
  }
  
  .about-hero h1 {
    font-size: var(--font_size_bg);
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .about-hero p {
    font-size: var(--font_size_header);
    max-width: 600px;
    margin: 0 auto;
    opacity: 0.95;
  }
  
  /* Grid Layout */
  .about-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 24px;
    padding: 40px 20px;
    max-width: 1100px;
    margin: 0 auto;
  }
  
  /* Cards */
  .about-card {
 
    border-radius: 16px;
    padding: 24px;
    background: var(-body_backgroun);
    border: 1px solid var(--border_color);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .about-card:hover {
    transform: translateY(-6px);
    box-shadow: 0 12px 30px rgba(0, 114, 255, 0.15);
  }
  
  .about-card h2 {
    font-size: var(--font_size_title);
    margin-bottom: 12px;
 
  }
  
  .about-card p,
  .about-card ul {
    font-size: var(--font_size_normal);
 
    line-height: 1.6;
  }
  
  .about-card ul {
    padding-left: 20px;
  }
  
  .about-card ul li {
    list-style: none;
    padding-left: 1rem;
    position: relative;
    margin-bottom: 8px;
  }
  
  .about-card ul li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #10b981;
    font-weight: bold;
  }
  
  