.faq-container {
    max-width: 820px;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: 'Inter', sans-serif;
  }
  
  .faq-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: #1a202c;
    margin-bottom: 2rem;
  }
  
  .faq-item {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    padding: 1rem 1.25rem;
    margin-bottom: 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    cursor: pointer;
  }
  
  .faq-item:hover {
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
  
  .faq-question {
    font-size: var(--font_size_title);
    font-weight: 600;
 
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-answer {
     
 text-align: left;
    margin-top: 0.75rem;
    line-height: 1.6;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease, opacity 0.4s ease;
    opacity: 0;
  }
  
  .faq-item.open .faq-answer {
    max-height: 200px; /* Adjust based on expected content height */
    opacity: 1;
  }
  
  .faq-icon {
    font-size: 1.25rem;
    color: var(--icon_color);
    transition: transform 0.3s ease;
  }
  
  .faq-item.open .faq-icon {
    transform: rotate(180deg);
  }
  