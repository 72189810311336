 

.media_div {
    display: flow-root;
    clear: both;
    padding: 5px 0px;
}

.social_link {
    max-width: 400px;
    float: left;
    align-items: center;
    display: flow-root;
}

.payment {
    max-width: 400px;
    float: right;
    align-items: center;
    display: flow-root;
}

.social_link .icon, .payment .icon {
    object-fit: contain;
    min-width: 50px;
    margin: 0px 5px;
    height: 40px;
}

.footer_body {
    min-height: 300px;
    background-color: var(--theme_app_footer_color);
    max-width: 100%;
    padding: 0px 0px;
    color: #fff;
    margin-top: 0px;
    
}

.footer_body .grid_colm {
    width: calc(100% / 5 - 10px);
    min-height: 10px;
    display: flow-root;
    float: left;
    margin: 0px 5px;
    padding: 5px 0px;
}

.footer_body .grid_colm .title_div {
    line-height: 20px;
    text-align: left;
    font-size: var(--font_size_normal);
    color: var(--text_color_info);
    padding: 10px 10px;
}

.footer_body .grid_colm .links_btn {
    line-height: 20px;
    padding: 5px 10px;
    font-size: var(--font_size_normal);
    clear: both;
    float: left;
    cursor: pointer;
    color: var(--theme_app_footer_text_color);
 
    font-weight: 300;
    margin: 1px 0px;
    text-decoration: none;
}

.footer_body .grid_colm .links_btn:hover {
    text-decoration: underline;
}

.footer_body .grid_colm .company {
    min-height: 40px;
 
    line-height: 40px;
  width: 200px;
    padding: 0px 10px 0px 10px;
   
    color: var(--theme_app_footer_text_color);
    font-weight: 300;
    font-size: 40px; 
    margin-bottom: 50px;
}
.footer_body .grid_colm .company .logo_icon
{   object-fit: contain;
    width: 40px;
    height: 40px;
    float: left;
}
 

.footer_body .grid_colm .download_file:hover {
    transition: ease-in-out 0.5s;
    opacity: 0.9;
}

.footer_body .tearms_div {
    max-width: 100%;
    min-height: 40px;
    border-top: solid 1px #253136;
}

.footer_body .tearms_div .links_btn {
    line-height: 20px;
    padding: 5px 10px;
    font-size: var(--font_size_normal);
    float: left;
    cursor: pointer;
    color: var(--theme_app_footer_text_color);
    font-weight: 300;
 
    margin: 5px 5px;
    text-decoration: none;
}

.footer_body .links_btn:hover {
    text-decoration: underline;
}

.footer_body .tearms_div .company_links_btn {
    line-height: 20px;
    padding: 5px 10px;
    font-size: 12px;
    float: right;
    cursor: pointer;
    color: var(--theme_app_footer_text_color);
  
    margin: 5px 5px;
    text-decoration: none;
}

.footer_body .company_links_btn:hover {
    text-decoration: underline;
}

@media (max-width:600px) {
    .footer_body {
        margin-top: 0px;
        min-height: 300px;
        background-color: #111B21;
        max-width: 100%;
        padding: 0px 0px;
        color: var(--theme_app_footer_text_color);
    }

    .footer_body .grid_colm {
        width: calc(100% - 20px);
        min-height: 20px;
        display: flow-root;
        border-bottom: solid 2px var(--theme_app_footer_boder_color);
        float: left;
        margin: 0px 5px;
        padding: 3px 0px;
    }

    .footer_body .grid_colm .title_div {
        line-height: 20px;
        font-size: 12px;
        padding: 3px 10px;
    }

    .footer_body .grid_colm .links_btn {
        line-height: 20px;
        padding: 2px 10px;
       font-size: var(--font_size_normal);
        clear: both;
        float: left;
        text-align: left;
        width: calc(100% - 10px);
        cursor: pointer;
         color: var(--theme_app_footer_text_color);
      font-weight: 400;
        margin: 2px 0px;
        text-decoration: none;
    }

    .footer_body .grid_colm .links_btn:hover {
        text-decoration: underline;
    }
    .footer_body .grid_colm .company {
        min-height: 30px;
        object-fit: contain;
        line-height: 40px;
        background-position: left 2px center;
        padding: 0px 0px 0px 8px;
        background-repeat: no-repeat;
        background-size: 20px;
        color: var(--theme_app_footer_text_color);
        font-weight: 600;
        font-size: 40px;
        margin: auto;
        margin-bottom: 20px;
    } 
    .footer_body .grid_colm .download_file:hover  {
        transition: ease-in-out 0.5s;
        margin: 1px;
    }
    .social_link {
        max-width: 250px;
        font-size: var(--font_size_normal);
        float: none;
        margin: auto;
        align-items: center;
        display: flow-root;
    }

    .payment {
        max-width: 290px;
        float: none;
        font-size: var(--font_size_normal);
        margin: 5px auto;
        align-items: center;
        display: flow-root;
    }
}
