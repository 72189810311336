
.features-heading
{
  text-align: center; 
  font-size: var(--font_size_bg);
}   
.features-subtitle
{    font-size: var(--font_size_header);
  text-align: center; 
  margin-bottom: 20px;
}   

.features-page {
    padding: 10px 20px;
    background: var(--body_backgroundo);
    text-align: center;
  }
  
  .features-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
 
    margin: auto;
  }
  
  .feature-card {
    background: var(-body_backgroun);
    border: 1px solid var(--border_color);
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease;
    color: var(--text_color);
  }
 
  
  .feature-card:hover {
    transform: translateY(-6px);
    box-shadow: 0 12px 30px rgba(0, 114, 255, 0.15);
  }
  .title_text {
    font-size: var(--font_size_title);
    text-align: center;
    font-weight: 600;
  }
  
  .desc_text {
    font-size: var(--font_size_normal);
    text-align: left;
  }
  .card_pro_img
  {
    max-width: 100%;
    height: 160px;
    object-fit: contain;
  }
  
  .feature-icon {
    color: var(--icon-color);
    margin-bottom: 15px;
    font-size: var(--font_size_header);
  }
  