.design-menu
{  
  font-size: var(--font_size_button);
  background-color: var(--layout_background);
  
  display: flow-root; 
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  touch-callout: none; /* Prevents callout menu on long press */
  -webkit-touch-callout: none;
} 

.design-menu .button-body
{
 width:calc(100% - 150px);
min-height: 40px;
padding: 5px 10px;
 
overflow-x: hidden;
float: left;
display: flow-root; 
}
.orders-button {
  display: inline-flex;
  align-items: center;  
  justify-content: center;                  
   float: right;
  color: var(--text_color_info);
  text-decoration: none;
  padding: 5px 0px 0px 0px; 
  margin: 0px;
  font-size: var(--font_size_button);
  border-radius: 0px;
  min-height: 40px; 
  transition: transform 0.3s ease;
  cursor: pointer;
  line-height: 24px;
  border-bottom:solid 2px transparent;
}
 
/* Button Styling */
.design-menu-button {
    display: inline-flex;
    align-items: center;  
    justify-content: center;                  
     float: left;
    color: var(--text_color_info);
    text-decoration: none;
    padding: 0px 5px 0px 15px; 
    margin: 0px 10px;
    font-size: var(--font_size_button);
    border-radius: 0px;
    height: 40px; 
    transition: transform 0.3s ease;
    cursor: pointer;
    line-height: 24px;
    border-bottom:solid 2px transparent;
  }
  
  /* Center-Aligned Text */
  .menubutton-text {
    white-space: nowrap;        /* Prevents text wrapping */
    text-align: center;         /* Center-align text */
    color: var(--text_color_info); 
    margin: 0px 5px 0px 0px;
  }
  
  /* Center-Aligned Image */
  .menubutton-icon {
    height: 22px;
    width: 22px;
    margin: 0px 5px 0px 0px;
    color: var(--text_color_info);
    transition: transform 0.3s ease;
  }
  .design-menu-button.active
  {
    border-bottom:solid 2px var(--body_color);
  }
  .design-menu-button.active .button-text
  {
    color: var(--body_color);
  }
  .design-menu-button.active .menubutton-icon
  {
    color: var(--body_color);
  }
  /* Hover Effect */
  .design-menu-button:hover {
 
    -webkit-animation: pulse 1s ease-out;
  }
  
  /* Design page */
  .design_container_roots
  {
    border: solid 1px var(--border_color);
    margin: 10px;
    min-height: calc(100vh -  300px);
    border-radius: 10px;
   padding-bottom: 20px;
   user-select: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   touch-callout: none; /* Prevents callout menu on long press */
   -webkit-touch-callout: none;
  }

  .design_container_roots .design_container_size
  {
    min-height: calc(100vh - 300px);  
  }
  .container_design
  { 
   
    border-radius: 8px;  
    padding: 20px;
  }

  .design_container_roots .design_container_size .input-body
  {
    max-width: 1400px;
    margin: auto;
    display: flow-root;
    padding: 20px 0px;
  }
  

  .service-stock-div
  {
     
    min-height: 40px;
    max-width: 100%;
    border-top-left-radius:9px; 
    border-top-right-radius:9px; 
    display: flow-root;
    padding: 5px 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    touch-callout: none; /* Prevents callout menu on long press */
    -webkit-touch-callout: none;
    border-bottom: solid 1px var(--border_color); 
    
  }
  .service-stock-div .left-div
  {
    float: left;
    display: flex;
    padding: 0px 10px;
  }
  .service-stock-div .stock-cart
  {    font-size: var(--font_size_title);
    float: left;
    height:24px;
    margin: 8px 5px;
    width: 24px;
    cursor: pointer;
  }
    .stock-text
  {    font-size: var(--font_size_normal);
    float: left;
    line-height: 40px;
    margin: 0px 5px; 
    font-weight: 400;
  }
  .service-stock-div .right-div
  {
    float: right;
    display: flow-root;
  }
  
 
  .service-stock-div .prumicm-switch-wrapper
  {    font-size: var(--font_size_title);
    float: left;
    line-height: 40px;
    font-weight: 400;
    margin-left: 20px;
    padding: 4px 0px;
 
    cursor: pointer;
  
    border-bottom-right-radius:5px; 
  }
  
  .service-stock-div .create-btn
  {    font-size: var(--font_size_title);
    float: left;
    line-height: 40px;
    min-height: 40px;
    font-weight: 600;
  
    padding: 0px 20px;
  width: 80px;
    cursor: pointer;
    border: solid 1px var(--border_color);
    background-color: var(--layout_background);
    border-radius:5px; 
  }
  .service-stock-div .create-btn:hover
  {-webkit-animation: pulse 1s ease-out;
     
 
   
  }
  
  .service-stock-div .down-btn
  {  border-radius:5px; 
    padding:5px 2px;
    border: solid 1px var(--border_color);
    min-height: 30px;
    width: 50px;
float: right;
background-color: var(--layout_background);
 display: none;
border-radius: 5px;
cursor: pointer;
display: none;
  }
  .service-stock-div .down-btn:hover
  
  {
    -webkit-animation: pulse 1s ease-out;
 
  }
  
  @media (max-width: 1280px) {
   
    .design_container_roots .design_container_size
    {
      min-height: calc(100vh - 300px);  
    }
    .container_design
    {  
      border-radius: 8px;  
      padding:0 50px;
    }
    .design_container_roots .design_container_size .input-body
    {
      max-width: 100%;
      margin:0 5px auto;
      display: flow-root;
      padding: 10px 1px;
    }
    
  .service-stock-div
  {
     
    min-height: 35px;
    max-width: 100%;
    border-top-left-radius:9px; 
    border-top-right-radius:9px; 
    display: flow-root;
    padding: 2px 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    touch-callout: none; /* Prevents callout menu on long press */
    -webkit-touch-callout: none;
    border-bottom: solid 1px var(--border_color); 
    
  }
  .service-stock-div .left-div
  {
    float: left;
    display: flex;
    padding: 0px 10px;
  }
  .service-stock-div .stock-cart
  {    font-size: var(--font_size_title);
    float: left;
    height:24px;
    margin: 5px 5px;
    width: 24px;
    cursor: pointer;
  }
    .stock-text
  {    font-size: var(--font_size_normal);
    float: left;
    line-height: 35px;
    margin: 0px 5px; 
    font-weight: 400;
  }
    .service-stock-div .create-btn
    {    font-size: var(--font_size_title);
      float: left;
      line-height: 35px;
      min-height: 35px;
      font-weight: 600;
    
      padding: 0px 20px;
    width: 80px;
      cursor: pointer;
      border: solid 1px var(--border_color);
      background-color: var(--layout_background);
      border-radius:5px; 
    }
    .service-stock-div .create-btn:hover
    {-webkit-animation: pulse 1s ease-out;
       
   
     
    }
    .service-stock-div .down-btn
    {  border-radius:5px; 
      padding:2px 2px;
      border: solid 1px var(--border_color);
      min-height: 30px;
      width: 50px;
  float: right;
  background-color: var(--layout_background);
   display: none;
  border-radius: 5px;
  cursor: pointer;
  display: none;
    }
    .service-stock-div .down-btn:hover
    
    {
      -webkit-animation: pulse 1s ease-out;
   
    }
  }
 
  @media (max-width: 600px) {
      /* Design page */
  .design_container_roots
  {
    border:none 1px var(--border_color);
    margin: 5px auto;
    border-radius: 10px;
   padding-bottom: 10px;
  }
  .design_container_roots .design_container_size
  {
    min-height: 100px;  
    margin: 0px 5px;
  }
  .container_design
  { 
    min-height: 10px;     
    border-radius: 8px;  
    padding:0px 5px;
  }
  .design_container_roots .design_container_size .input-body
  {
    max-width: 1400px;
    margin: auto;
    display: flow-root;
    padding: 5px 0px;
  }
  
  .service-stock-div
  {
     
    min-height: 40px;
    max-width: 100%;
    border-top-left-radius:9px; 
    border-top-right-radius:9px; 
    display: flow-root;
    padding: 0px 10px;
    user-select: none;          
    -webkit-user-select: none;
    -moz-user-select: none;      
    -ms-user-select: none;    
    border-bottom: solid 1px var(--border_color); 
    
  }
  .service-stock-div .left-div
  {
    float:left;
    display:flow-root;
   
    padding: 0px 0px;
 
  }
  .service-stock-div .stock-cart
  {    font-size: var(--font_size_title);
    float: left;
    height:24px;
    margin: 6px 5px;
    width: 24px;
    cursor: pointer;
  }
    .stock-text
  {    font-size: var(--font_size_normal);
    float: left;
    line-height: 40px;
    margin: 0px 5px;
    cursor: pointer;
    font-weight: 400;
  }
  .service-stock-div .right-div
  {
    float: right;
    padding: 0px 0px 0px 0px;
    display: flow-root; 
    
    margin-bottom: 5px;
     
  }
  
  .service-stock-div .scan-alert
  {  font-size: var(--font_size_normal);
    float: left;
    line-height: 40px;
    margin: 0px 0px;
    cursor: pointer;
    font-weight: 400;
  }
  .service-stock-div .prumicm-switch-wrapper
  {    font-size: var(--font_size_title);
    float: right;
    line-height: 40px;
    font-weight: 400;
    margin-left: 20px;
    padding: 2px 0px;
 
    cursor: pointer;
  
    border-bottom-right-radius:5px; 
  }
  
  .service-stock-div .create-btn
  {    font-size: var(--font_size_title);
    float: right;
    line-height: 40px;
    min-height: 40px;
    font-weight: 600;
  
    padding: 0px 20px;
  width: 60px;
    cursor: pointer;
    border: solid 1px var(--border_color);
    background-color: var(--layout_background);
    border-radius:5px; 
  }
  .service-stock-div .create-btn:hover
  {-webkit-animation: pulse 1s ease-out;
  
  }
  
  .service-stock-div .down-btn
  {  border-radius:5px; 
    padding:5px 10px;
    border: solid 1px var(--border_color);
    min-height: 30px;
    width: 30px;
    float: right;
background-color: var(--layout_background);
display: none;
border-radius: 5px;
cursor: pointer;
 
  }
  .service-stock-div .down-btn:hover
  
  { 
-webkit-animation: pulse 1s ease-out;
  }
  }
  