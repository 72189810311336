/* Global Styles */
h1, h2 {
    color: #333;
}

p, ul, ol, li, table, th, td {
    text-align: left;
}

/* Table Styles */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    line-height: 25px;
}

table, th, td {
    border: 1px solid #ddd;
    padding: 10px;
}

th {
    background: #f4f4f4;
}

/* Container Styles */
.container_roots {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

 
.body_grid_size {
    padding: 20px;
    line-height: 25px;
}

 
