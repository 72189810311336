 
  .amazon-order-root {
    padding: 40px 20px;
    min-height: 100vh;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
  
  }
   
  /* Main Flex Container */
  .amazon-order-container {
    display:flow-root;
    gap: 40px;
  
    flex-wrap: wrap;
  }
  
  /* Left Section */
  .amazon-order-left {
  
    width:600px; 
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .relative-order-left {
    position: relative;
    width: 100%;
  }
  
  /* Product Image */
 
  .relative-order-left 
  {  
    position: relative;
    width: 100%;
  }
  
  
  .relative-order-left .photo-img {
    width:calc(100% - 1px); 
 
    object-fit:contain;
    border-radius: 5px;   
   z-index:0;
  
  }
  .relative-order-left .nav_div
  {
background-color:var(--layout_background);
display: flow-root;
min-height: 50px;
  }
  /* Navigation Buttons */
  .relative-order-left .nav_div .Previous-btn {
    font-size: var(--font_size_bg);
    cursor: pointer; 
    border: none;
    color: #000;
 float: left;
   min-height: 40px;
    padding: 5px 10px; 
    z-index: 100; 
    background-color:var(--layout_background);
  }
  
  .relative-order-left .nav_div .Next-btn {
    font-size: var(--font_size_bg);
    cursor: pointer; 
      float: right;
      border: none;
      color: #000;
      min-height: 40px;
    padding: 5px 10px; 
    z-index: 100; 
    background-color:var(--layout_background);
  }
  
  /* Right Section */
  .amazon-order-right {
    
   width: calc(100% - 700px);
   float: right;
  }
  
  /* Product Info */
  .product-title {
    font-size: var(--font_size_bg);
    margin-bottom: 15px;
    line-height: 30px;
    font-weight: 600;
    text-align: left;
  }
  
  .product-price {
    font-size: var(--font_size_header);
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .product-description {
    font-size: var(--font_size_title);
    line-height: 1.6;
    text-align: left; 
    
  }
  .product-description-hd {
    font-size: var(--font_size_title);
    line-height: 1.6;
    padding: 1px 10px;
    text-align: left; 
    border-bottom:solid 1px var(--border_color);
  }
  /* Order Options */
  .order-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Select Quantity */
  .qty-select {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid var(--border_color);
    font-size: var(--font_size_title);
    margin-left: 10px;
  }
  
  /* Buttons */
  .buy-button{
    padding: 12px 20px;
    font-size: var(--font_size_button);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 400;
  }
  
  
  .buy-button:hover {
    -webkit-animation: pulse 1s ease-out;
  }
  
  .add-cart-button {
    background-color: var(--layout_background);
    color: #000;
  }
  
  .add-cart-button:hover {
    background-color: #1f2937;
    color: #fff;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .amazon-order-container {
      flex-direction: column;
      align-items: center;
    }
  
    .amazon-order-left,
    .amazon-order-right {
      width: 100%;
    }
  
    .relative-order-left img {
      max-width: 100%;
    }
  }
  