/* Body styling */
.show-case-body {
  padding: 10px 20px;
  background: var(--theme_green_lite_color);
  margin: 0;
  text-align: left;
}
 
 

/* Showcase Section */
.show-case {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

/* Content Section */
.content {
  flex: 1;
  max-width: 60%;
}

.content h2 {
  font-size: 28px;
 
  margin-bottom: 20px;
}

.content p {
  font-size: 18px;
  line-height: 1.8;
 
}

.content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.content ul li {
  font-size: 18px;
  line-height: 1.6;
}

/* Image Section */
.image-container {
  flex: 1;
  max-width: 20%;
  text-align: right;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
 
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .show-case {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .content,
  .image-container {
    max-width: 100%;
    text-align: center;
  }

  .image {
    width: 90%;
    margin: 0 auto;
  }

  .content h2 {
    font-size: 24px;
  }

  .content p,
  .content ul li {
    font-size: 16px;
  }
}
