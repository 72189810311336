
.phone-login-container {
    text-align: center;
  
    max-width: 100%;
    padding: 10px;
   
    border-radius: 10px;
  
}
.qrcode.callqr-style {
    position: relative;
    width: 200px;
    height: 200px;
    background: #ffffff;
    border: 2px solid #00897B;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    padding: 10px;
    display: inline-block;
  }
  
  .qrcodecanvas {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
  
  .callqr-logo {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }
  
.scanqr-login-body
{
    max-width: 100%;
    min-height: 200px;
    display: none;
}
.scanqr-login-body .timer-label
{
min-height: 20px;
margin-top: 3px;
}
.scanqr-login-body .qrcode
{    
    width: 200px;
    margin: auto;
    border-radius: 5px;
    height: 200px;
    position: relative;
}
.scanqr-login-body .qrcodecanvas
{   
    width: 200px;
    margin: auto;
    border-radius: 5px;
    height: 200px;
}
 .phone-login-body
{
    max-width: 100%;

}
.qrbtnlogin
{
    max-width: 100%;
padding: 5px 10px;
    display: flow-root;
    font-size: var(--font_size_normal);
    
    border-radius: 5px;
    margin-bottom: 10px;
    line-height: 30px;
    cursor: pointer;
}
.qrbtnlogin .qrcode
{    width: 30px;
    height: 30px;
    cursor: pointer;
    float: right;
}
.phonebtnlogin
{
    max-width: 100%;
padding: 5px 10px;
    display: flow-root;
    font-size: var(--font_size_normal);
    border: 2px solid var(--border_color);
    border-radius: 5px;
    margin-bottom: 10px;
    line-height: 30px;
    cursor: pointer;
}
.phonebtnlogin .phone_icon
{    width: 30px;
    height: 30px;
    cursor: pointer;
    float: right;
}
.phone-login-container .phone-login-div
{
    max-width: 100%;
    border: 1px solid var(--layout_background);
    
    display: flex;
    border-radius: 5px;
    background-color: var(--body_background);
   
    border: 2px solid var(--border_color);
}
 
 .codelist-input {
    width:calc(100% - 1px);
 font-weight: 400;
 border: 1px solid var(--border_color);
    outline: none; 
    line-height: 28px;
    text-align: left;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: var(--font_size_title);
}
.phone-login-container .phone-input {
    width:calc(100% - 10px);
    padding: 10px;
    border: 1px solid transparent;
    outline: none;
    border-radius: 5px;
    
font-size: var(--font_size_title);
}
.phone-login-container .otp-input {
    width:calc(100% - 10px);
    padding: 10px;
    border: 1px solid transparent;
     
    outline: none;
    border-radius: 5px;
    
font-size: var(--font_size_title);
}
.phone-login-container .trams-div
{
    text-align: left;
    margin-top: 5px;
    text-decoration: none;
}
.phone-login-container .trams-div a
{
    text-align: left;
    margin-top: 5px;
    text-decoration: none;
    color: var(--text_blue);
}
.phone-login-container .trams-div a:hover
{text-decoration: underline;
}
 

.phone-login-container .phone-login-button {
    width: 100%;
    padding: 10px 0px;
    background-color: var(--theme_color);
    color: #000;
    border: 1px solid var(--theme_color);
   margin: 5px 0px;
    cursor: pointer;
    border-radius: 30px;
    text-align: center;
    transition: 0.3s;
    height:40px;
    font-weight:500;
    font-size: var(--font_size_button);
}
.phone-login-container .phone-login-button:hover{
   opacity: 0.9;
   -webkit-animation: pulse 1s ease-out;
}

.phone-login-container .otp-Verify_button {
    width: 100%;
    padding: 10px 0px;
    background-color: var(--theme_color);
    color: #000;
    border: 1px solid var(--theme_color);
   margin: 5px 0px;
    cursor: pointer;
    height:40px;
    border-radius: 30px;
    text-align: center;
    transition: 0.3s;
    font-weight: 500;
    font-size: var(--font_size_button);
}
.phone-login-container .phone-login-button:hover{
   opacity: 0.9;
   -webkit-animation: pulse 1s ease-out;
}
.phone-login-container .error {
    color: var(--red_color);
    margin: 10px 0;
}

.phone-login-container #recaptcha-container {
    margin: 15px 0;
}
.phone-login-container .otp-body-div
{
    max-width: 100%;
}
.phone-login-container .otp-login-div
{
    max-width: 100%;
    background-color: var(--body_background);
    color: #000;
    border: 1px solid var(--border_color);
    border-radius: 5px;
    display: none;
}
@media only screen and (max-width: 600px) {
 .qrbtnlogin
 {
    display: none;
 }
}