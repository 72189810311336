.hero {
    padding: 20px 0;
    text-align: left;
  }
  
  .hero h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .hero p {
    font-size: 1.2em;
  }
  
  .container {
 
    margin: auto;
    padding: 20px;
  }
  
  .pricing {
    text-align: center;
    margin-top: 20px;
  }
  
  .pricing-table {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
   
    padding: 20px;
    border-radius: 10px;
  }
  
  .pricing-card {
 
    padding: 20px;
    border-radius: 10px;
    width: calc(50% - 55px);
    background: var(-body_backgroun);
    border: 1px solid var(--border_color);
  }
  
  .pricing-card h3 {
    font-size: 1.5em;
  }
  
  .price {
   
    font-weight: bold;
    font-size: var(--font_size_bg);
    color: #000;
  }
  
  .purchase_for {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 20px;
  }
  
  .purchase_title {
    flex: 1;
   
    line-height: 1.6;
  }
  
  .tag_img {
    width: 400px;
    height: 200px;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/callqr-ab941.appspot.com/o/ads%2Fcallqr_order%2Fbusiness_tools_ads.jpg?alt=media&token=3c44ac4a-e34b-481a-8b79-30a49f1bb95e);
    background-size: cover;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .card {
    background: var(-body_backgroun);
    border: 1px solid var(--border_color);
    flex: 1 1 calc(33.33% - 20px);
    padding: 15px;
    border-radius: 10px;
  
    transition: transform 0.2s ease;
  }
  
  .card:hover {
    transform: translateY(-6px);
    box-shadow: 0 12px 30px rgba(0, 114, 255, 0.15);
  }
  
  .card h3 {
    margin-top: 0;
  }
  
  .btn_btn {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #fcca01;
    color: black;
    text-decoration: none;
    border-radius: 8px;
    font-weight: bold;
  }
  
  .info-text {
   
    margin-top: 20px;
  }
  