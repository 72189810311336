.App_header {
    background-color: var(--theme_app_header_color);    
               
          
    z-index: 1000;

    left: 0px;

    right: 0px;

    top: 0px;                          
  position: fixed;
  width: 100%;
    display: flow-root;                           
    padding: 2px 20px;                           
    box-sizing: border-box;   
         
    user-select: none;          
    -webkit-user-select: none;
    -moz-user-select: none;      
    -ms-user-select: none;        
   
}

.phone-login-popup-overlay {
    max-width: 100%;
    position: fixed;  /* Use `fixed` for better consistency on scroll */
    z-index: 1011;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; 
    height: calc(100% - 0px);
   
    transition: opacity 0.3s ease, visibility 0.3s ease;  /* Smooth transition */
}
.phone-login-popup-overlay .popup-box {
    background-color: var(--body_background);
    border-radius: 10px;
    padding: 5px;               /* Added more padding for better spacing */
    max-width: 350px;
    width: 100%;
    margin:50px auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);  /* Smooth shadow */
    transition: all 0.3s ease;   /* Smooth transition */
    border-top: solid 5px var(--theme_color);
 
}
.phone-login-popup-overlay .popup-box .phone-close-button {
    border-radius: 5px;
    padding: 10px 10px;
    background-color: var(--layout_background);
    color: #000;
    border: 1px solid var(--border_color);
    border: none;
    cursor: pointer;
    margin: 10px 10px;
    transition: 0.3s;
    font-weight: 600;
    font-size: var(--font_size_title);
}
.App_header .left_div
{
    float: left;
    width: 200px;
    
    height: 50px; 
    
    position: relative;
}
.App_header .left_div .menu-btn
{
    float: left;
    display: none;
    width: 30px;
    cursor: pointer;
    margin: 8px 0px 8px 0px;
    margin-right: 20px;
    height: 30px;
}
.App_header .left_div .menu-btn:hover
{
    color: var(--theme_color);
}
 
.App_header .left_div .logo
{
    width: 90px;
    height: 50px;
    float: left;
    object-fit: contain;
    cursor: pointer;
    opacity: 0.9;
}
.App_header .center_body
{
    
    height: 45px;
    float: left;
    width: calc(100%  - 670px);
  position: relative;
  margin:2px 1px 2px 10px;
   
  align-items: center;
}
.App_header .center_body .phone-support{
  
    position: absolute;
 margin: 10px 0px;
   line-height: 30px;
   display: flow-root;
}
.App_header .center_body .phone-support .text{
    font-size: var(--font_size_button);
    font-weight: 600; 
   line-height: 30px;
  float: left;
}
.App_header .center_body .phone-support .icon{
  height: 18px;
  width: 18px;
  float: left;
 margin: 8px 1px;
}
 .App_header .center_body .menu_body {
    width: 600px;
    height: 45px;
   min-width: 400px;  
   margin:1px auto;
    align-items: center; 
    border-radius: 30px;
    display: flow-root;
  
     
}  
.App_header .center_body .menu_body .link_btn {
    text-decoration: none;
    float: left;
   line-height: 30px;
    font-weight:400;
    padding: 3px 15px;
    margin: 5px 15px 5px 15px; 
    cursor: pointer;
    font-size: var(--font_size_button);
    position: relative;
    transition: color 0.5s ease;
 
color: var(--text_color);
}
.App_header .center_body .link_btn:hover {
    transition: width 0.5s ease;     
 border-bottom: solid 2px var(--theme_color) ;
 
}


 
.App_header .right_div
{
     
    height: 50px;
    float: right;
  display:flex;
 
    position: relative;
 
}
 
.App_header .right_div .login_settings
{
float: left;
height: 50px;
width: 50px;
 object-fit: contain;
margin: 0px 0px 0px 30px; 
background-color: #fff;
border: solid 1px var(--border_color);
cursor: pointer;
border-radius: 50%;
}
.App_header .right_div .search-icon
{
    display: block;
    float: left;
    height: 30px;
    width: 30px;
    color: var(--icon-color);
    margin: 10px 30px 10px 0px;
    border-radius: 50px;
    cursor: pointer;
}
.App_header .right_div .search-icon:hover
{
    -webkit-animation: pulse 1s ease-out;
}
.App_header .right_div .download-icon
{
    display: block;
    float: left;
    
}
.App_header .right_div .login-icon
{
    display: inline-flex;
    align-items: center;         /* Center-align image and text vertically */
    justify-content: center;     /* Center-align horizontally */
    gap: 5px;                   /* Space between text and image */
    
    background: var(--theme_blue_color, var(--theme_color));
    color: var(--text_color);
    text-decoration: none;
    padding: 0px 5px 0px 20px; 
    margin: 5px 5px;
    font-size: var(--font_size_button);
    border-radius: 30px;
    height: 42px;
    transition: transform 0.3s ease;
    cursor: pointer;
    line-height: 24px;
    float: right;
}
.App_header .right_div .login-icon:hover
{
    -webkit-animation: pulse 1s ease-out;
}
/* Styling the scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* Scroll to top button */
.scroll-to-top-btn {
    position: fixed;
    bottom: 100px;
    right: 20px;
   
    width: 40px;
    height: 40px; 
    background-color: var(--layout_background); 
     text-align: center;
    border: none;
 
    border-radius: 50%; 
    cursor: pointer;
    color: var(--icon-color);
    box-shadow: 0 4px 10px rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 999;
}

.scroll-to-top-btn:hover {
    
    transform: scale(1.1);
    color: #fff;
    background-color: var(--theme_color);
}

.scroll-to-top-btn:active {
    transform: scale(0.95);
}
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    border: none;
    padding: 12px 15px;
    border-radius: 50px;
    font-size: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000;
  }
@media (max-width: 1280px) {
    .App_header {
        background-color: var(--theme_app_header_color);    
                   
              
        z-index: 1000;
    
        left: 0px;
    
        right: 0px;
    
        top: 0px;                          
      position: fixed;
      width: 100%;
        display: flow-root;                           
        padding: 1px 10px;                           
        box-sizing: border-box;   
             
        user-select: none;          
        -webkit-user-select: none;
        -moz-user-select: none;      
        -ms-user-select: none;        
       
    }
    
    .phone-login-popup-overlay {
        max-width: 100%;
        position: fixed;  /* Use `fixed` for better consistency on scroll */
        z-index: 1011;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0;
        right: 0;
        bottom: 0;
        top: 0; 
        height: calc(100% - 0px);
       
        transition: opacity 0.3s ease, visibility 0.3s ease;  /* Smooth transition */
    }
    .phone-login-popup-overlay .popup-box {
        background-color: var(--body_background);
        border-radius: 10px;
        padding: 5px;               /* Added more padding for better spacing */
        max-width: 350px;
        width: 100%;
        margin:50px auto;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);  /* Smooth shadow */
        transition: all 0.3s ease;   /* Smooth transition */
        border-top: solid 5px var(--theme_color);
     
    }
    .phone-login-popup-overlay .popup-box .phone-close-button {
        border-radius: 5px;
        padding: 10px 10px;
        background-color: var(--layout_background);
        color: #000;
        border: 1px solid var(--border_color);
        border: none;
        cursor: pointer;
        margin: 10px 10px;
        transition: 0.3s;
        font-weight: 600;
        font-size: var(--font_size_title);
    }
    .App_header .left_div
    {
        float: left;
        width: 300px;
        
        height: 40px; 
        
        position: relative;
    }
    .App_header .left_div .menu-btn
    {
        float: left;
        display: none;
        width: 30px;
        cursor: pointer;
        margin: 5px 0px 5px 0px;
        margin-right: 20px;
        height: 30px;
    }
    .App_header .left_div .menu-btn:hover
    {
        color: var(--theme_color);
    }
     
    .App_header .left_div .logo
    {
        width: 70px;
        height: 30px;
        margin: 7px 0px;
        float: left;
        object-fit: contain;
        cursor: pointer;
        opacity: 0.9;
    }
    .App_header .center_body
    {
        
        height: 40px;
        float: left;
        width: calc(100%  - 670px);
      position: relative;
      margin:2px 1px 2px 1px;
       
      align-items: center;
    }
    /* .App_header .center_body .phone-support{
      
        position: absolute;
     margin: 5px 0px;
       line-height: 30px;
       display: flow-root;
    }
    .App_header .center_body .phone-support .text{
        font-size: var(--font_size_button);
        font-weight: 600; 
       line-height: 30px;
      float: left;
    }
    .App_header .center_body .phone-support .icon{
      height: 18px;
      width: 18px;
      float: left;
     margin: 8px 1px;
    } */
     .App_header .center_body .menu_body {
        width: 600px;
        height: 40px;
       min-width: 300px;  
       margin:3px auto;
        align-items: center; 
        border-radius: 30px;
        display: flow-root;
      
         
    }   
    
    .App_header .center_body .menu_body .link_btn {
        text-decoration: none;
        float: left;
       line-height: 30px;
        font-weight:400;
        padding: 0px 15px;
        margin: 1px 10px 1px 10px; 
        cursor: pointer;
        font-size: var(--font_size_normal);
        position: relative;
        transition: color 0.5s ease;
     
    color: var(--text_color);
    }
    .App_header .center_body .link_btn:hover {
        transition: width 0.5s ease;     
     border-bottom: solid 2px var(--theme_color) ;
     
    }
    
    
     
    .App_header .right_div
    {
         
        height: 40px;
        float: right;
      display:flex;
     
        position: relative;
     
    }
     
    .App_header .right_div .login_settings
    {
    float: left;
    height: 40px;
    width: 40px;
     object-fit: contain;
    margin: 0px 0px 0px 20px; 
    background-color: #fff;
    border: solid 1px var(--border_color);
    cursor: pointer;
    border-radius: 50%;
    }
    .App_header .right_div .search-icon
    {
        display: block;
        float: left;
        height: 25px;
        width: 25px;
        color: var(--icon-color);
        margin: 10px 10px 10px 0px;
        border-radius: 50px;
        cursor: pointer;
    }
    .App_header .right_div .search-icon:hover
    {
        -webkit-animation: pulse 1s ease-out;
    }
    .App_header .right_div .download-icon
    {
        display: block;
        float: left;
        
    }
    .App_header .right_div .login-icon
    {
        display: inline-flex;
        align-items: center;         /* Center-align image and text vertically */
        justify-content: center;     /* Center-align horizontally */
        gap: 5px;                   /* Space between text and image */
        
        background: var(--theme_blue_color, var(--theme_color));
        color: var(--text_color);
        text-decoration: none;
        padding: 0px 5px 0px 15px; 
        margin: 5px 5px;
        font-size: var(--font_size_normal);
        border-radius: 30px;
        height: 35px;
        transition: transform 0.3s ease;
        cursor: pointer;
        line-height: 24px;
        line-height: 12px;
        float: right;
    }
    .App_header .right_div .login-icon:hover
    {
        -webkit-animation: pulse 1s ease-out;
    }
    /* Styling the scrollbar */
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
    
    /* Scroll to top button */
    .scroll-to-top-btn {
        position: fixed;
        bottom: 100px;
        right: 20px;
       
        width: 40px;
        height: 40px; 
        background-color: var(--layout_background); 
         text-align: center;
        border: none;
     
        border-radius: 50%; 
        cursor: pointer;
        color: var(--icon-color);
        box-shadow: 0 4px 10px rgba(0,0,0,0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.3s, transform 0.3s;
        z-index: 999;
    }
    
    .scroll-to-top-btn:hover {
        
        transform: scale(1.1);
        color: #fff;
        background-color: var(--theme_color);
    }
    
    .scroll-to-top-btn:active {
        transform: scale(0.95);
    }
    .whatsapp-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #25D366;
        color: white;
        border: none;
        padding: 8px 15px;
        border-radius: 50px;
        font-size: 16px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        z-index: 1000;
      }
}
@media only screen and (max-width: 600px) {
    .App_header .center_body
    {
        display: none;
    }
    .App_header {
        background-color: var(--body_background);    
        color: var(--text_color);                  
            
        z-index: 1000;

        left: 0px;
    
        right: 0px;
    
        top: 0px;                                
      position: fixed;
      width: 100%;
        display: flow-root;                           
        padding:2px 10px;                           
        box-sizing: border-box;   
        -webkit-box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);
        -moz-box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);
        box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);       
    }
    .App_header .left_div
    {
        float: left;
        width: 200px;
        
        height: 40px; 
        
        position: relative;
    }
    .App_header .left_div .menu-btn
    {
        float: left;
        display: block;
        width: 30px;
        cursor: pointer;
        margin: 8px 0px 8px 0px;
        margin-right: 20px;
        height: 30px;
    }
    .App_header .left_div .menu-btn:hover
    {
        color: var(--theme_color);
    }
     
    .App_header .left_div .logo
    {
        width: 80px;
        height: 34px;
        float: left;
        object-fit: contain;
        cursor: pointer;
        opacity: 0.9;
    }
 
    .App_header .right_div
{
    max-width: 190px;
    height: 50px;
    float: right;
  align-items: flex-end;
    position: relative;    
    display: flow-root; 
}
.App_header .right_div .search-icon
{
    display: block;
    float: left;
    height: 30px;
    width: 25px;
    margin: 10px 10px 10px 0px;
    cursor: pointer;
}
.App_header .right_div .login-icon
{
    display: inline-flex;
    align-items: center;         /* Center-align image and text vertically */
    justify-content: center;     /* Center-align horizontally */
    gap: 5px;                   /* Space between text and image */
    
    background: var(--theme_blue_color, var(--theme_color));
    color: var(--text_color);
    text-decoration: none;
    padding: 2px 5px 0px 15px; 
    margin: 7px 5px;
    font-size: var(--font_size_normal);
    border-radius: 30px;
    height: 35px;
    font-weight: 500;
    transition: transform 0.3s ease;
    cursor: pointer;
    line-height: 24px;
    line-height: 12px;
    float: right;
}
.App_header .right_div .login_settings
{
float: right;
height: 40px;
width: 40px;
 
margin: 5px 0px 5px 0px; 
cursor: pointer;
 
}
.App_header .right_div .download-icon
{
    display: none;
}
  }


