.issue-list {
    display: grid;
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .issue-card {
    background-color: #fff;
    border: 1px solid #e2e8f0;
    padding: 1.5rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  }
  
  .issue-card h3 {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    color: #1f2937;
  }
  
  .issue-card ul {
    padding-left: 1.5rem;
    list-style-type: disc;
  }
  
  .issue-card li {
    margin-bottom: 0.5rem;
  }
  